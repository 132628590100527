import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Machine Learning Engineer (Python Specialist)",
    type: "Full Time",
    level: "Mid Level",

    description:
        "We are seeking a skilled Machine Learning Engineer with strong Python expertise to join our innovative team. In this role, you will design, develop, and implement cutting-edge machine learning solutions to solve complex business problems and drive data-driven decision-making across our organization.",

    responsibilities: [
       "Design and develop machine learning models and algorithms using Python and related libraries",
"Implement and optimize machine learning pipelines for data preprocessing, feature engineering, model training, and evaluation",
"Collaborate with data scientists, software engineers, and domain experts to gather requirements and define project goals",
"Conduct data analysis and visualization to gain insights and identify patterns in large datasets",
"Select appropriate datasets and data representation methods for model training",
"Deploy machine learning models to production environments and monitor their performance",

    ],

    requirements: [
        "Bachelor's or Master's degree in Computer Science, Data Science, or a related field",
        "Proven experience as a Machine Learning Engineer or similar role",
        "Strong proficiency in Python and its machine learning libraries (e.g., scikit-learn, TensorFlow, PyTorch, Keras)",
        "Familiarity with version control systems (e.g., Git) and software development best practices",
        "Strong problem-solving skills and ability to think critically about complex data problems",
        "Excellent communication skills to collaborate with cross-functional teams and present findings to stakeholders",
        "Experience with Meta Apps, Business API and Cloud API is a plus",
        
 
     ],

    preferredQualifications: [

        "Knowledge of MLOps practices and tools for model deployment and monitoring",
"Able to work with meta llama latest one",
"Training and generate model ",
"Chatbot creation with a real business case",
"Contributions to open-source machine learning projects or research publications",
    ],
  
    noOfOpenings: 1
};

const MachineLearningEngineer = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default MachineLearningEngineer;
