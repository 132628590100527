import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Shift Manager",
    type: "Full Time",
    level: "Mid Level",

    description:
        "Aakash Group (www.aakash.group) has a history of more than 100 years in Nepal diversified in different business verticals. Aakash Foods, operator of Angan Sweets in Kathmandu, Nepal is looking for passionate and experienced Shift Managers to join our team. Website: www.angansweets.com (Franchise of Bikanervala from India)",

    qualifications: [
        "Nature of Business: On Site Fast Food Restaurant Outlet",
        "Number of Requirements: 4",
        "Salary: INR 25,000 /-",
        "Food & Accommodation: Provided ",
        "Yearly Bonus: 1 month annually",
        "Location: Kathmandu",
        "Employment Status: Full-Time ",
        "Experience Requirement: Minimum 2 years as an Outlet Supervisor in a sweet shop or any other similar outlet. ",

    ],
    responsibilities: [
        "Coordinate daily Front of the House and Back of the House restaurant operations",
        "Coordinate with production staff for daily order",
        "Receive goods everyday and verify the quantity ordered ",
        " Monitor and supervise cashier, salesman, cooks, housekeeping staff",
        " Maintain store stock and ledger",
        " Deliver superior service and maximize customer satisfaction",
        " Respond efficiently and accurately to customer complaints",
        " Organise and supervise staff shifts & manage timings",
        " Develop customer relationships for bulk orders ",
        " Appraise staff performance and provide feedback to improve productivity",
        " Understand the names, ingredients and prices for different products ",
        " Manage crowd during festivals ",
        " Estimate future needs for goods, kitchen utensils and cleaning products",
        " Ensure compliance with sanitation and safety regulations",
        " Manage restaurant’s good image and suggest ways to improve it",
        " Control operational costs and identify measures to cut waste",
        " Create detailed reports on weekly, monthly and annual revenues and expenses",
        " Promote the brand in the local community through word-of-mouth and restaurant events",
        " Train new and current employees on proper customer service practices",
        " Implement policies and protocols that will maintain future restaurant operation.",
        " Customer Champion: Deliver exceptional customer service, resolving any concerns promptly and professionally. We prefer a candidate who will be responsible for managing the restaurant and handling the outlet team.",
        " Operational Excellence: Manage day-to-day operations, ensuring food and sweet service efficiency, quality, and hygiene standards are met.",
        "  Smooth Operator: Oversee opening and closing procedures, ensuring all equipment is functioning properly and the restaurant is clean and prepared for business.",
        "Teamwork Makes the Dream Work: Effectively communicate with staff, fostering collaboration and teamwork.",
        "Inventory Management: Maintain appropriate stock levels of ingredients and supplies."
    ],

    perks: [
        "Food and Accommodation provided by company",
        "Sim card with recharge and data",
        "Festival  bonus",
        "Good sustained job-performing candidates will have career growth opportunities",
    ],


    noOfOpenings: 4
};

const ShiftManager = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default ShiftManager;
