import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
  title: "Administrative Assistant",
  type: "Full Time",
  level: "Mid Level",
//   education: [
//     "Bachelor’s degree in graphic design, industrial design, or interior design, or equivalent experience",
//     "Five to eight years of experience in graphic design",
//   ],
  description:
   "This is a full-time position where you will provide comprehensive administrative support to ensure efficient office operations. You will be responsible for a variety of tasks, including event logistics, office management, finance support, and maintaining vendor relationships.",

   qualifications: [
   "2-3 years of professional administrative experience preferred.",
"Bachelor's degree in administration, Finance, or a related field.",
"Must have two-wheels and a valid driving license.",
"Excellent communication and interpersonal skills.",
"Strong understanding of banking and taxation principles.",
"Proficiency with Microsoft Office Suite and Google products.",
"Adaptability and ability to thrive in a fast-paced environment.",
],
responsibilities: [
  "General Administration:",
  "Manage office supplies and coordinate with vendors.",
  "Maintain accurate records and documentation.",
  "Assist with other administrative tasks as required.",
  
  "Banking:",
  "Support in maintaining accurate records of all bank transactions.",
  "Prepare and process bank transfers, payments, and receipts.",
  "Assist in the preparation of bank reconciliations.",
  
  "Taxation:",
  "Assist in the preparation and filing of various tax returns, including VAT, income tax, and other relevant taxes.",
  "Stay updated on tax laws and regulations to ensure compliance.",
  "Prepare and submit tax-related documents to government authorities.",
  
  ],
 // preferredQualifications:[
 //   "Experience with office management software (e.g., Google Suite, Microsoft Office 365).",
  //  "Knowledge of basic accounting principles.",
   // "Familiarity with basic HR procedures."
 // ]

 noOfOpenings: 1,
};

const AdminAssistant = () => {
  return <CareerDetailPage careerDetails={careerDetails} />;
};

export default AdminAssistant;
