import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { default as adminstrativeAssistant } from "../../assets/images/career/adminstrative-assistant.jpeg";
import dataAnalyst from "../../assets/images/career/data-analyst.jpg";
import PageHeader from "./../common/PageHeader";


const openings = [
  /*{
    title: "Human Resource Officer",
    shortDescription:
      "The Human Resources Officer will be responsible for the overall management and implementation of Human Resources policies, procedures, guidelines, and tools.",
    link: "/career/human-resource-officer",
    logo: HR,
  },
  */
  {
    title: "Restaurant Supervisor",
    shortDescription:
      "We're Hiring: A Restaurant Supervisor!! With new outlets and expansion, the company is looking for suitable candidates with prior outlet floor experience of at least 2 years in sweets shops.",
    link: "/career/restaurant-cashier",
    logo: adminstrativeAssistant,
  },
  {
    title: "Executive Assistant",
    shortDescription:
      "We’re currently looking for an executive assistant to be a supportive force who empowers our senior leadership. The ideal candidate will be a proactive problem solver with exceptional communication skills and meticulous attention for details. This person should have experience working in an office environment, performing administrative duties, and providing support to managers.",
    link: "/career/executive-assistant",
    logo: dataAnalyst,
  },

  {
    title: "Shift Manager",
    link: "/career/shift-manager",
    shortDescription:
      "Aakash Group (www.aakash.group) has a history of more than 100 years in Nepal diversified in different business verticals. Aakash Foods, operator of Angan Sweets in Kathmandu, Nepal is looking for passionate and experienced Shift Managers to join our team. Website: www.angansweets.com (Franchise of Bikanervala from India) ",
    logo: dataAnalyst,
  },
  {
    title: "Machine Learning Engineer (Python Specialist)",
    link: "/career/machine-learning-engineer",
    shortDescription:
      "We are seeking a skilled Machine Learning Engineer with strong Python expertise to join our innovative team. In this role, you will design, develop, and implement cutting-edge machine learning solutions to solve complex business problems and drive data-driven decision-making across our organization.",
    logo: adminstrativeAssistant,
  },

];

const CareerPage = () => {
  useEffect(() => {
    document.title = "Aakash Group | Career";
  }, []);

  return (
    <div className="career-page page">
      <PageHeader
        heading="Careers"
        description="Since we have been growing fast lately, we are looking for members to join our team!!"
      />
      <div className="container">
        <div className="title">
          <div className="heading">Current Openings</div>
        </div>
        <ul className="openings">
          {openings.map((opening, index) => (
            <li key={index} className="opening">
              <div className="wrapper">
                <div className="image">
                  <img src={opening.logo} alt="" />
                </div>
                <div className="details">
                  <div className="title">{opening.title}</div>
                  <div className="details-bot">{opening.shortDescription}</div>
                </div>
                <div className="apply-wrap">
                  <Link to={opening.link}>
                    <button>Apply</button>
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CareerPage;
