import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Restaurant Supervisor",
    type: "Full Time",
    level: "Mid Level",

    description:
        "We're Hiring: A Restaurant Supervisor. With new outlets and expansion, the company is looking for suitable candidates with prior outlet floor experience of at least 2 years in sweets shops.",

    qualifications: [
        "Candidates should have experience in the Mithai industry as a sweet and restaurant outlet supervisor",
"Provide leadership, direction, and training to the shop floor staff",
"Coordinating production orders and delivering on time",
"Customer service and satisfaction focused",
"Supervise and maintain discipline among staff",
"Monitor and improve the efficiency and output of the restaurant branch",
"Implement and enforce schedules and deadlines for orders",
"Maintain accurate and up-to-date product and stock",
"Identify opportunities for restaurant improvement and growth",
"Drive initiatives to enhance productivity and reduce costs",
"Monitoring inventory and following the SOP of the supervisor, cashier, sales counter, and kitchen staff",
"Training new employees in proper work procedures and safety measures",
"Planning, organizing, and maintaining resources relevant to customer satisfaction, sales growth, and company welfare",
    ],

    responsibilities: [
        "We prefer a candidate who will be responsible for managing the restaurant and handling the outlet team. This role involves leadership, stock updates, coordination with production, quality control, ensuring a safe working environment, target achievement, and sales growth.",
        "Furthermore, reporting to upper management and collaborate with various departments to achieve goals in adherence to company culture",
    ],
    perks: [
        "Food and Accommodation",
"Sim card with recharge and data",
"Festive bonus and appreciation",
"Good sustained job-performing candidates will have career growth opportunities",
    ],
    noOfOpenings: 1
};

const RestaurantCashier = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default RestaurantCashier;
