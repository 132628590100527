import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Executive Assistant",
    type: "Full Time",
    level: "Mid Level",
    
    objective: [
         "Support the Chairman primarily and provide additional support to executive team members, as directed, to ensure that company goals and objectives are accomplished and that operations run efficiently", 
"Maintain and refine internal processes that support high-ranking executives companywide, and coordinate internal and external resources to expedite workflows",
"Manage communication with employees by liaising with internal and external executives on various projects and tasks",
"Plan and orchestrate work to ensure that senior executives’ priorities are met, organizational goals are achieved, and best practices are upheld",
       ],

    description:
        "At Aakash Group, we thrive when our people thrive. We’re currently looking for an executive assistant to be a supportive force who empowers our senior leadership. The ideal candidate will be a proactive problem solver with exceptional communication skills and meticulous attention for details. This person should have experience working in an office environment, performing administrative duties, and providing support to managers. Given the dynamic nature of the executive landscape, we rely on executive assistants to be flexible and consistent while maintaining the confidentiality of high-level systems and operations.",   


        qualifications: [
        "Four or more years of experience in an administrative role reporting directly to upper management", "Excellent written and verbal communication skills",
"Strong time-management skills and an ability to organize and coordinate multiple concurrent projects",
"Proficiency with office productivity tools and an aptitude for learning new software and systems", 
"Flexible team player, willing to adapt to changes and unafraid of challenges", 
"Ability to maintain confidentiality of information related to the company and its employees",
    ],

    responsibilities: [
        "Manage professional and personal scheduling for Chairman, including agendas, mail, email, phone calls, client management, and other company logistics",
"Coordinate complex scheduling and calendar management, as well as content and flow of information to senior executives",
"Manage senior executives’ travel logistics and activities, including accommodations, transportation, and meals",
"Provide administrative and office support, such as typing, dictation, spreadsheet creation, faxing, and maintenance of filing system and contacts database",
"Maintain professionalism and strict confidentiality with all materials",
"Organize team communications and plan events, both internal and off-site",

    ],
    preferredQualifications: [
        "Experience in overseeing budgets and expenses", "Experience in developing internal processes and filing systems", "Two Wheeler with License",
    ],
    noOfOpenings: 1
};

const ExecutiveAssistant = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default ExecutiveAssistant;
