import { NotificationContainer } from "react-notifications";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AboutPage from "./about/AboutPage";
import AdminAssistant from "./career/adminAssistant";
import CareerPage from "./career/CareerPage";
import ExecutiveAssistant from "./career/executiveAssistant";
import FactoryDispatchOfficer from "./career/factoryDispatch";
import GraphicDesigner from "./career/GraphicDesigner";
import HumanResourceOfficer from "./career/humanResource";
import MachineLearningEngineer from "./career/machineLearningEngineer";
import RestaurantCashier from "./career/restaurantCashier";
import ShiftManager from "./career/shiftManager";
import ContactPage from "./contact/ContactPage";
import HomePage from "./home/HomePage";
import AakashAi from "./industries/AakashAi";
import AakashCapital from "./industries/AakashCapital";
import AakashLabs from "./industries/AakashLabs";
import Angan from "./industries/Angan";
import HospitalityHotel from "./industries/HospitalityHotel";
import IndustriesPage from "./industries/IndustriesPage";
import MahalaxmiComplex from "./industries/MahaLaxmi";
import RelianceInsurance from "./industries/RelianceInsurance";
import SiddharthaInsurance from "./industries/SiddharthaInsurance";
import Footer from "./layout/Footer";
import Header from "./layout/Header";

function App() {
  return (
    <Router>
      <NotificationContainer />
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/contact-us" element={<ContactPage />} />
        <Route exact path="/industries" element={<IndustriesPage />} />
        <Route exact path="/industries/angan" element={<Angan />} />
        <Route exact path="/industries/aakash-labs" element={<AakashLabs />} />
        <Route exact path="/industries/aakash-ai" element={<AakashAi />} />
        <Route
          exact
          path="/industries/aakash-capital"
          element={<AakashCapital />}
        />
        <Route
          exact
          path="/industries/siddhartha-insurance"
          element={<SiddharthaInsurance />}
        />
        <Route
          exact
          path="/industries/reliance-life-insurance"
          element={<RelianceInsurance />}
        />
        <Route
          exact
          path="/industries/mahalaxmi-complex"
          element={<MahalaxmiComplex />}
        />
        <Route
          exact
          path="/industries/hospitality-hotel"
          element={<HospitalityHotel />}
        />
        <Route exact path="/careers" element={<CareerPage />} />
        <Route exact path="/career/human-resource-officer" element={<HumanResourceOfficer />} />
        <Route exact path="/career/restaurant-cashier" element={<RestaurantCashier />} />
        <Route exact path="/career/admin-assistant" element={<AdminAssistant />} />
        <Route exact path="/career/executive-assistant" element={<ExecutiveAssistant />} />
        <Route exact path="/career/factory-dispatch" element={<FactoryDispatchOfficer />} />
        <Route exact path="/career/shift-manager" element={<ShiftManager />} />
        <Route exact path="/career/machine-learning-engineer" element={<MachineLearningEngineer />} />

        <Route
          exact
          path="/career/graphic-designer"
          element={<GraphicDesigner />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
